import { FC } from 'react';
import Link from 'next/link';
import clsx from 'clsx';

import classes from './Breadcrumbs.module.scss';

export interface IBreadCrumbLink {
  path?: string;
  label: string;
}

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  links: IBreadCrumbLink[];
  classNameWrapper?: string;
}

const Breadcrumbs: FC<IProps> = ({ links, classNameWrapper }) => {
  const lastIndex = links.length - 1;
  return (
    <nav className={clsx(classes.wrapper, classNameWrapper)}>
      <ol className={classes.list}>
        <li className={classes.breadcrumb}>
          <Link className={classes.link} href={`/`} aria-label='Головна'>
            Головна
          </Link>
        </li>
        {links.map((link, idx) => (
          <li className={classes.breadcrumb} key={link.label}>
            <span className={classes.separator}>/</span>
            {link.path && lastIndex !== idx ? (
              <Link className={classes.link} href={`/${link.path}`} aria-label={link.label}>
                {link.label}
              </Link>
            ) : (
              <span className={classes.label}>{link.label}</span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export { Breadcrumbs };
