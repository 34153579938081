import { FC } from 'react';
import clsx from 'clsx';

import { UAH } from '@lib/constants/common-constants';
import { roundAndFormatNumber } from '@utils/formatting';

import classes from './ProductPrice.module.scss';

interface IProps {
  className?: string;
  price: number;
  specialPrice?: number;
  largePrice?: boolean;
}

const ProductPrice: FC<IProps> = ({ className, price, specialPrice, largePrice }) => (
  <div className={clsx(classes.price, { [className as string]: className })}>
    {price !== specialPrice && specialPrice ? (
      <>
        <span className={clsx(classes.priceWithoutDiscount, { [classes.largePrice]: largePrice })}>
          {price}
          &nbsp;
          {UAH}
        </span>
        <span className={clsx(classes.priceWithDiscount, { [classes.largePrice]: largePrice })}>
          {roundAndFormatNumber(specialPrice)}
          &nbsp;
          {UAH}
        </span>
      </>
    ) : (
      <span className={clsx(classes.regularPrice, { [classes.largePrice]: largePrice })}>
        {price || 0}
        &nbsp;
        {UAH}
      </span>
    )}
  </div>
);


export { ProductPrice };
