import { STATUS_LABELS } from '@lib/constants/common-constants';
import { IProductReview } from '@types';

export type IProductAttributesDictionary =
  | 'height'
  | 'quality'
  | 'maturity'
  | 'manufacturer'
  | 'weight'
  | 'color'
  | 'country'
  | 'packing'
  | 'diameter'
  | 'shape'
  | 'width'
  | 'size'
  | 'coating'
  | 'volume'
  | 'length';

export type IProductAttributesIcons =
  | 'height'
  | 'quality'
  | 'maturity'
  | 'manufacturer'
  | 'weight'
  | 'diameter'
  | 'width'
  | 'size'
  | 'coating'
  | 'shape'
  | 'volume';

export enum ProductAttributesDictionary {
  HEIGHT = '000000001',
  QUALITY = '000000017',
  MATURITY = '000000016',
  MANUFACTURER = '000000018',
  WEIGHT = '000000015',
  COLOR = '000000021',
  COUNTRY = '000000019',
  PACKING = '000000020',
  DIAMETER = '000000003',
  SHAPE = '000000039',
  WIDTH = '000000049',
  SIZE = '000000050',
  COATING = '000000040',
  VOLUME = '000000022',
  LENGTH = '000000023'
}

export const attributesToFilter = [ProductAttributesDictionary.COUNTRY, ProductAttributesDictionary.COLOR];

export interface IAttribute<V = string> {
  code: ProductAttributesDictionary;
  name: string;
  value: V;
  alias: IProductAttributesDictionary;
  format?: string | null;
  displayValue?: string | null;
  sortOrder?: number;
}

export type IAttributes = {
  [key in IProductAttributesDictionary]?: IAttribute;
};

export interface IImage {
  full?: string;
  preview?: string;
  thumbnail?: string;
  mobilePreview?: string;
}

export interface IProduct {
  id: string;
  alias: string;
  displayCode: string;
  displayName: string;
  images: IImage[];
  attributes: IAttributes;
  inStockStatus: keyof typeof STATUS_LABELS;
  inStockTotal: number;
  price: number;
  specialPrice?: number;
  catalogCategory: string;
  catalogCategoryId: string;
  catalogCategorySeoKey?: string;
  productType: string;
  productGroup: string;
  unit: string;
  seoKey: string;
  inPackageCount: number;
  parentCategories: string[];
  description?: string;
  composition?: string;
  metaText?: string;
  metaTitle?: string;
  metaDescription?: string;
  productReview?: IProductReview;
  catalogCategoryProductsGroup?: string;
  preorderDays?: number;
  hasPreorder?: boolean;
  metaLinks?: string;
  h1Title?: string;
}

export type SizesMapType = {
  xxs: string;
  xs: string;
  s: string;
  m: string;
  l: string;
  xl: string;
  xxl: string;
};

export interface IMetaLinks {
  label: string;
  url: string;
}
