import clsx from 'clsx';

import { Icon } from '../../icon/Icon';
import {
  IControlProps,
  IDropdownIndicatorProps,
  ILoadingMessageProps,
  IMenuProps,
  IOptionProps,
  ISingleValueProps,
  IValueContainerProps
} from './select-types';

import classes from './Select.module.scss';

const NoOptionsMessage = () => {
  return <p className={classes.noOptionsMessage}>немає варіантів</p>;
};

const Control = ({
  children,
  innerProps,
  innerRef,
  menuIsOpen,
  selectProps: { controlProps, dropdownMenu, value, classes: classesProps }
}: IControlProps) => (
  <>
    <div
      className={clsx(classes.controlContainer, {
        [classes.controlActive]: menuIsOpen,
        [classes.controlError]: controlProps.error,
        [classes.controlOutline]: !dropdownMenu,
        [classes.inputDropdownMenu]: dropdownMenu && !menuIsOpen,
        [classes.inputDropdownMenuOpen]: dropdownMenu && menuIsOpen,
        [classesProps?.controlClassName || '']: classesProps?.controlClassName
      })}
    >
      {controlProps.label && (
        <label className={clsx(classes.controlLabel, { [classes.floating]: Boolean(value) })} htmlFor={controlProps?.htmlForName}>
          {controlProps.label}
        </label>
      )}
      <div ref={innerRef} className={classes.controlInner} {...innerProps}>
        {children}
      </div>
    </div>
    {controlProps.error && <div className={classes.controlErrorMessage}>{controlProps.helperText}</div>}
  </>
);

const Option = ({ isDisabled, isSelected, data, innerRef, innerProps, children }: IOptionProps) => (
  <div
    ref={innerRef}
    className={clsx(classes.menuItem, classes.menuItemClass, {
      [classes.menuItemDisabled]: isDisabled,
      [classes.menuItemSelected]: isSelected
    })}
    {...innerProps}
  >
    <div className={classes.menuItemMain}>
      <span className={clsx('main-label')}>
        {data.icon && <Icon type={data.icon} width={16} height={16} offset={8} />}
        {children}
      </span>
      {data.desc && <div>{data.desc}</div>}
    </div>
  </div>
);

const SingleValue = ({ innerProps, children }: ISingleValueProps) => (
  <span className={classes.singleValue} {...innerProps}>
    {children}
  </span>
);

const ValueContainer = ({ selectProps: { labelIcon, labelIconRight }, children }: IValueContainerProps) => (
  <div className={classes.valueContainer}>
    {!!labelIcon && labelIcon}
    {children}
    {!!labelIconRight && labelIconRight}
  </div>
);

const Menu = ({ children, innerProps }: IMenuProps) => (
  <div className={clsx(classes.paper, classes.paperClassName)} {...innerProps}>
    {children}
  </div>
);

const LoadingMessage = ({ innerRef, innerProps }: ILoadingMessageProps) => (
  <div ref={innerRef} className={classes.menuItem} {...innerProps}>
    <div className={classes.menuItemMain}>
      <span className={'main-label'}>Завантаження</span>
    </div>
  </div>
);

const DropdownIndicator = ({ selectProps: { menuIsOpen, dropdownMenu } }: IDropdownIndicatorProps) => {
  if (dropdownMenu) {
    return null;
  }

  return (
    <div
      className={clsx(classes.dropdownIndicator, {
        [classes.dropdownIndicatorActive]: menuIsOpen
      })}
    >
      <Icon width={24} height={16} type="chevronDown" />
    </div>
  );
};

const components = {
  IndicatorSeparator: () => null,
  DropdownIndicator,
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder: () => null,
  SingleValue,
  ValueContainer,
  LoadingMessage
};

export default components;
