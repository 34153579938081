import { FC, memo, MouseEvent, SyntheticEvent } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';

import { IconButton } from '@components';
import { IProductOptions } from '@types';

import classes from './BuyButton.module.scss';

const PostcardModal = dynamic(() =>
  import('@components/modals/postcard-modal/PostcardModal').then((mod) => mod.PostcardModal)
);

interface IBuyButtonProps {
  onSubmit: (e: SyntheticEvent | MouseEvent, message?: string) => void;
  addToCartHandler?: (ev: SyntheticEvent | MouseEvent, productOptions?: IProductOptions) => void;
  catalogCategoryProductsGroup?: string;
  displayName: string;
  isInCart?: boolean;
}

const BuyButton: FC<IBuyButtonProps> = ({
  onSubmit,
  catalogCategoryProductsGroup,
  displayName,
  isInCart,
  addToCartHandler
}) => {
  return catalogCategoryProductsGroup === 'postcards' ? (
    <PostcardModal onSubmit={onSubmit} preventOpenModal={isInCart} labelPostcard={displayName}>
      <IconButton
        className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
        ariaLabel="Додати до кошику"
        icon={isInCart ? 'cartChecked' : 'cartFilled'}
      />
    </PostcardModal>
  ) : (
    <IconButton
      className={clsx(classes.buyButton, { [classes.buyButtonInCart]: isInCart })}
      onClick={addToCartHandler}
      ariaLabel="Додати до кошику"
      icon={isInCart ? 'cartChecked' : 'cartFilled'}
    />
  );
};

function areEqual(prevProps: IBuyButtonProps, nextProps: IBuyButtonProps) {
  return (
    prevProps.catalogCategoryProductsGroup === nextProps.catalogCategoryProductsGroup &&
    prevProps.displayName === nextProps.displayName &&
    prevProps.isInCart === nextProps.isInCart
  );
}

const BuyButtonMemo = memo(BuyButton, areEqual);

export { BuyButtonMemo as BuyButton };
