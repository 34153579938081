import { ChangeEvent, FC, ReactNode } from 'react';
import { FieldError } from 'react-hook-form';
import ReactSelect, { SingleValue } from 'react-select';
import clsx from 'clsx';

import { ISelectOption } from '@types';
import components from './components';

import classes from './Select.module.scss';

interface IProps {
  options: ISelectOption[];
  className?: string;
  value?: string;
  name?: string;
  label?: string;
  isLoading?: boolean;
  fullWidth?: boolean;
  disableWrapper?: boolean;
  dropdownMenu?: boolean;
  labelIcon?: ReactNode;
  labelIconRight?: ReactNode;
  placeholder?: string;
  helperText?: string;
  invalid?: boolean;
  // * this is the same event type as in react-hook-form Controller onChange func
  onChange?: (ev: string | ChangeEvent) => void;
  isDisabled?: boolean;
  selectClasses?: { [key: string]: string };
  error?: boolean;
  errorMessage?: string | FieldError;
  ariaLabel?: string;
}

export const Select: FC<IProps> = ({
  onChange,
  value,
  options,
  label,
  name = '',
  isLoading,
  className,
  dropdownMenu,
  labelIcon,
  labelIconRight,
  isDisabled,
  selectClasses,
  error,
  errorMessage,
  ariaLabel
}) => {
  const foundOption =
    options && options.length
      ? options.find((option) => {
          return option.value === value;
        })
      : null;

  function handleChangeSingle(newValue: SingleValue<ISelectOption>) {
    if (newValue) {
      if (onChange) {
        onChange({
          target: {
            value: newValue.value,
            name,
            data: newValue
          }
          // ! there is a problem with react
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } as any);
      }
    }
  }

  const errorText = typeof errorMessage === 'string' ? errorMessage : errorMessage?.message;

  return (
    <ReactSelect
      inputId={name}
      dropdownMenu={dropdownMenu}
      labelIcon={labelIcon}
      labelIconRight={labelIconRight}
      // menuIsOpen // dev
      className={clsx(classes.root, className)}
      isSearchable={false}
      classes={selectClasses || {}}
      name={name}
      isLoading={isLoading}
      controlProps={{
        label,
        disabled: isDisabled,
        helperText: errorText,
        error,
        htmlForName: name
      }}
      options={options}
      components={components}
      value={foundOption}
      onChange={handleChangeSingle}
      isDisabled={isDisabled}
      aria-label={ariaLabel}
    />
  );
};
