import { FC, SyntheticEvent, useState } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { useSession } from 'next-auth/react';

import { addProductToFavorites, removeProductFromFavorites } from '@api/product';
import { IconButton } from '@components';
import { Icon } from '../../shared';

import classes from './FavoriteIcon.module.scss';

const AuthModal = dynamic(() => import('@components/modals').then((mod) => mod.AuthModal));

interface IProps {
  productId: string;
}

const FavoriteIcon: FC<IProps> = ({ productId }) => {
  const { data: session, status: sessionStatus, update: sessionUpdate } = useSession();
  const [pending, setPending] = useState(false);
  const token = session?.user.bearer ?? '';
  const watchList = session?.user?.watchList || [];

  const isWatched = Array.isArray(watchList) ? watchList.includes(productId) : false;
  const toggleWatchListItem = (productId: string) => {
    const result = Array.isArray(watchList) ? watchList : [];
    if (result.includes(productId)) {
      return result.filter((id) => id !== productId);
    } else {
      return [...result, productId];
    }
  };

  const watchProduct = async (ev: SyntheticEvent | MouseEvent) => {
    if (sessionStatus === 'authenticated') {
      ev.stopPropagation();
      ev.preventDefault();
      if (pending) return;

      setPending(true);
      (isWatched ? removeProductFromFavorites(token, productId) : addProductToFavorites(token, productId))
        .then(() => {
          // * update session user data on 200 response
          if (session) {
            sessionUpdate({
              ...session,
              user: {
                ...session.user,
                watchList: toggleWatchListItem(productId)
              }
            });
          }
        })
        .catch(() => {
          // TODO show an error with toast
        })
        .finally(() => {
          setPending(false);
        });
    }
  };

  const WatchIcon = () => (
    <IconButton
      onClick={watchProduct}
      className={clsx(classes.watchIcon, {
        [classes.watchIconSelected]: isWatched,
        [classes.disabled]: pending
      })}
      ariaLabel="Додати в обране"
    >
      <Icon type="heart" size={24} />
    </IconButton>
  );

  return sessionStatus !== 'authenticated' ? (
    <AuthModal>
      <WatchIcon />
    </AuthModal>
  ) : (
    <WatchIcon />
  );
};

export { FavoriteIcon };
