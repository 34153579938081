import Link from 'next/link';
import clsx from 'clsx';

import { Icon } from '../icon/Icon';

import classes from './SocialLinks.module.scss';

export interface ISocialIcon {
  icon: 'viber' | 'telegram' | 'whatsapp';
  title: string;
  link: string;
  disabled?: boolean;
}

// const chatId = 'u816689430-1596692233vid50ce5bac-86f3-4129-a85f-f7cf6c85edbb';
const socialIconsDefault: ISocialIcon[] = [
  { icon: 'viber', title: 'Viber', link: `viber://pa?chatURI=flowerpot` },
  { icon: 'telegram', title: 'Telegram', link: `https://t.me/FlowerpotuaBot` },
  { icon: 'whatsapp', title: 'WhatsApp', link: 'whatsapp://send?phone=+120345678910', disabled: true }
];

interface IProps {
  icons?: ISocialIcon[];
  withTitle?: boolean;
  scale?: number;
  dark?: boolean;
  containerWidth?: string;
  iconSize?: number;
  className?: string;
}

const SocialLinks = ({ icons = socialIconsDefault, withTitle = false, dark, iconSize, className }: IProps) => (
  <div className={clsx(classes.wrapper, className)}>
    {icons.map((item, index) => (
      <Link
        href={item.link}
        key={`social${index}`}
        className={clsx(classes.socialIconButton, {
          [classes.socialIconButtonDark]: dark
        })}
        target="_blank"
        style={item.disabled ? { pointerEvents: 'none' } : {}}
        aria-label={item.title}
      >
        <Icon type={item.icon} size={iconSize || 24} />
        {withTitle && <span>{item.title.toUpperCase()}</span>}
      </Link>
    ))}
  </div>
);

export default SocialLinks;
