import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import clsx from 'clsx';

import classes from './MainLogoLink.module.scss';

interface IMainLogoLinkProps {
  height?: number;
  width?: number;
  classNameWrapper?: string;
}

export const MainLogoLink: FC<IMainLogoLinkProps> = ({ height, width, classNameWrapper }) => {
  return (
    <Link href="/" className={clsx(classes.link, classNameWrapper)}>
      <Image
        width={width || 200}
        height={height || 36}
        src="/assets/images/flowerpot_logo.svg"
        alt="flowerpot"
        quality={100}
        priority
      />
    </Link>
  );
};
