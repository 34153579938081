import { FC } from 'react';
import clsx from 'clsx';

import classes from './DiscountIcon.module.scss';

interface IDiscountIconProps {
  price: number;
  specialPrice?: number;
  className?: string;
}

const DiscountIcon: FC<IDiscountIconProps> = ({ price, specialPrice, className }) => {
  const priceDiscount = 100 - Math.floor(((specialPrice || 0) * 100) / (price || 1));

  return priceDiscount && priceDiscount !== 100 ? (
    <div className={clsx(classes.discountIcon, { [className || '']: !!className })}>
      <p className={classes.priceDiscount}>-{priceDiscount}%</p>
    </div>
  ) : null;
};

export { DiscountIcon };
