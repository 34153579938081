import { FC } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import clsx from 'clsx';

import { IImage, ISelectedItem } from '@types';
import { ProductPrice } from '../../shared/product-price/ProductPrice';

import classes from '../Search.module.scss';

interface IProps {
  images: IImage[];
  productName: string;
  seoKey: string;
  searchQuery: string;
  price: number;
  specialPrice?: number;
  onSelect: (item: ISelectedItem) => void;
}

const ProductOption: FC<IProps> = ({ images, productName, seoKey, searchQuery, price, specialPrice, onSelect }) => {
  const imgSrc = images[0]?.thumbnail ? images[0].thumbnail : '/assets/images/flower-no-image.svg';
  const matches = match(productName, searchQuery);
  const parts = parse(productName, matches);

  return (
    <Link
      href={`/${seoKey}` || ''}
      onClick={() => onSelect({ label: productName, seoKey })}
      className={classes.productsItem}
    >
      <Image width={80} height={80} src={imgSrc} alt={productName} className={classes.imgProduct} quality={100} />
      <h3 className={classes.productName}>
        {parts.map((part, indexOption: number) => (
          <span key={indexOption} className={clsx({ [classes.productsItemHighlight]: part.highlight })}>
            {part.text}
          </span>
        ))}
      </h3>
      <ProductPrice price={price} specialPrice={specialPrice} className={classes.priceWithDiscount} />
    </Link>
  );
};

export { ProductOption };
